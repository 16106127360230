/*!
 * Bootstrap v5.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

// scss-docs-start import-stack
// Configuration
// @import "bootstrap/bootstrap-grid";
// @import "bootstrap/bootstrap-reboot";
// @import "bootstrap/bootstrap-utilities";
@import "bootstrap/bootstrap";